/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */
// (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
// (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

/*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
// (window as any).__Zone_enable_cross_context_check = true;

/** *************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js';

import './zone-flags';

/** *************************************************************************************************
 * APPLICATION IMPORTS
 */
(window as any).global = window;

// @ts-ignore
// tslint:disable no-var-requires
window.Buffer = window.Buffer || require('buffer').Buffer;
